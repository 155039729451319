import { SharedModule, addNavMenuItem } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem(
        {
            id: 'facets',
            label: 'Facets',
            routerLink: ['/catalog', 'facets'],
            requiresPermission: '__disable__',
        },
        'catalog',
    ),
];
